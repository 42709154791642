import {Component, Input} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {Destroyed} from '../../directives/destroyed.directive';
import {BaseParameter} from '../../../../model/param/base-parameter';
import {TreeItemNodeReferentiel} from '../../../../model/event/treeData/tree-item-node.model';
import {SelectOptionReferentiel} from '../../../../model/form/select-option.model';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-add-param',
  templateUrl: './dialog-add-param.component.html',
  styleUrls: ['./dialog-add-param.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FormsModule,
    MatSlideToggleModule,
    MatDialogActions,
    MatButtonModule
  ]
})
export class DialogAddParamComponent extends Destroyed {
  @Input() selectedParam?;
  @Input() node?: TreeItemNodeReferentiel;
  @Input() option?: SelectOptionReferentiel;
  paramToAdd: BaseParameter;

  constructor(public readonly dialogRef: MatDialogRef<DialogAddParamComponent>) {
    super();
    this.paramToAdd = new BaseParameter();
  }

  addParam() {
    if (this.paramToAdd.label) {
      if (this.node) {
        this.paramToAdd.idParent = this.node.id;
      }
      if (this.option) {
        this.paramToAdd.idParent = this.option.id;
      }
      this.dialogRef.close(this.paramToAdd);
    }
  }

  dismiss() {
    this.dialogRef.close();
  }
}
