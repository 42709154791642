<div class="d-flex flex-column">
  <div class="d-flex flex-column" mat-dialog-title>
    <h4>Ajouter un paramètre</h4>
    <h6>{{ this.selectedParam.name }}</h6>
  </div>
  <mat-dialog-content>
    <ng-container *ngIf="node || option">
      <div class="col-md-12 form-group"><label>Parent:</label> {{ node ? node.label : option.label }}</div>
    </ng-container>
    <div class="d-flex flex-row">
      <div class="col-md-6 form-group">
        <label>Libelle</label>
        <input [(ngModel)]="paramToAdd.label" class="form-control" placeholder="Label" type="text">
      </div>
      <div class="col-md-6 form-group">
        <label>Tri</label>
        <input [(ngModel)]="paramToAdd.sorting" class="form-control" placeholder="Tri" type="number">
      </div>
    </div>
    <div class="col-md-12 form-group">
        <mat-slide-toggle [(ngModel)]="paramToAdd.active">Actif</mat-slide-toggle>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button (click)="addParam()" class="btn btn-primary m-2"
            mat-button type="submit">
      Enregistrer
    </button>
    <button (click)="dismiss()" class="m-2" mat-button>
      Annuler
    </button>
  </mat-dialog-actions>
</div>
